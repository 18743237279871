/* Template Name: Flourish 
Main Color scheme 
/* 
/* Pink */
.btn-primary:active,.btn-primary:not(:disabled):not(.disabled):active,.btn-primary.focus, .btn-primary:focus,.btn-default.focus, .btn-default:focus,.btn,.btn-default, .pager li>a:focus, .pager li>a:hover,a.list-group-item:focus, a.list-group-item:hover, button.list-group-item:focus, button.list-group-item:hover, .list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover, .pagination>li>a:hover,.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover,.date-category,.navbar-default .navbar-nav>li>a::after , .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover, .spinner > div,.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover,.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover, .bg-color1,.btn:before,.contact-icon .top-icon,.nav-pills>li>a:focus, .nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover, .nav-pills>li>a:hover{
     background:#d68189;
}
 .panel-heading a:hover,.panel-heading a,blockquote:before,.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover, .serviceBox:hover .service-icon i, a:hover,a:focus,.alert:after, .alert:before, .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover, .owl-prev i:hover, .owl-next i:hover,ul.custom li:before,.margin-icon,.nav.nav-tabs li a,.social-media i,.brand,.brand i{
     color:#d68189;
}
 .content-box.home, .form-control,.section-heading:after,.service-icon img,.contact-icon,.btn:hover,.btn-default:hover{
     border-color:#d68189;
}
/* light yellow */
 .alert-info,.testimonial .content{
     background:#f2e9e1;
}
 .testimonial .content:after {
     border-top: 10px solid #f2e9e1;
}
 .serviceBox:hover .service-icon img {
     box-shadow: 0px -1px 0px 15px #f2e9e1;
}
/* Wine red */
 .table>thead>tr>th,.service-icon i, a,.alert-info, figure.team-style3 i, .nav.nav-tabs > li:focus > a, .nav.nav-tabs > li.active > a, .nav.nav-tabs > li.active > a:hover, .nav.nav-tabs > li.active > a:focus ,h1,h2,.btn,.btn-default,.testimonial .content:before {
     color:#87314e;
}
 .btn:hover,.btn-default:hover, #sidebar,.pager li>a, .pager li>span,.blog-tags a,.nav-pills>li>a ,.owl-prev i, .owl-next i,.owl-theme .owl-dots .owl-dot span:hover:before , .owl-theme .owl-dots .owl-dot.active span:before{
     background-color:#87314e ;
}
 .btn,.btn-default, .form-control:focus,.btn-primary.focus, .btn-primary:focus{
     border-color:#87314e;
}
 